<script setup lang="ts">
import { onMounted } from 'vue'
import { useVersionStore } from '@/store/VersionStore'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { authService } from '@/services/auth.service'

function checkVersion() {
  let versionStore = useVersionStore()
  let actualBuildTimestampUtc = document.documentElement.dataset.buildTimestampUtc as string
  if (process.env.NODE_ENV === 'development') {
    console.log('new version detected!  -> do nothing')
    return
  }
  if (versionStore?.knownBuildTimestampUtc != actualBuildTimestampUtc) {
    console.log('new version detected!  -> prune stores -> reload template')
    // a new hot deployed version may affect stores
    // reset all stores exept some specialstores
    // reload everything
    const somePiniaStore = useLegalEntityStore()
    somePiniaStore.$reset_all_preserve(['legalEntityStore', 'versionStore'])
    versionStore.knownBuildTimestampUtc = actualBuildTimestampUtc
    // this forces the application to reload its stores
    authService.renewToken()
  }
}

onMounted(() => {
  checkVersion()
})
</script>
