<template>
  <div id="app" class="bootstrap">
    <v-app>
      <AppHeader />
      <div class="app-height">
        <Navigation />
        <Version />
        <Navigationbreadcrumb />
        <DownloadInfoDialog />
        <RouterView v-if="ready" />
      </div>
      <div class="footer d-print-none">{{ getUpdatedImpressumText() }} ©</div>
      <Toaster />
    </v-app>
  </div>
</template>

<script lang="ts">
import AppHeader from '@/views/AppHeader.vue'
import Navigation from './views/Navigation.vue'
import Version from '@/views/profile/version.vue'
import Navigationbreadcrumb from './views/Navigationbreadcrumb.vue'
import DownloadInfoDialog from './components/downloadInfoDialog.vue'
import { CustomFormatter } from '@/views/translations/CustomFormatter'
import Toaster from '@/components/toaster.vue'
import { emitter } from './main'
import { usePrivilegesStore } from '@/store/privileges'
import { defineComponent } from 'vue'

//import { translations } from "./de.json"

export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
    Navigation,
    Navigationbreadcrumb,
    DownloadInfoDialog,
    Toaster,
    Version
  },
  data() {
    return { initialized: false }
  },
  computed: {
    ready() {
      // allow oidc-callback |oidc-popup-callback or wait until privileges are loaded
      return document.location.href.search('oidc') || (this.initialized && usePrivilegesStore().privileges.length > 0)
    }
  },
  beforeMount() {
    usePrivilegesStore().fetchPrivileges()
  },
  mounted() {
    emitter.on('initialized', () => {
      this.setInitialized(true)
      console.log('caught an  "on initializied"')
    })
    emitter.on('setLocale', this.changeLocale)
  },
  methods: {
    setInitialized(initialized: boolean) {
      console.log('ready!')
      this.initialized = initialized
    },
    getUpdatedImpressumText() {
      const formatter = new CustomFormatter()
      try {
        return formatter.interpolate(this.$t('impressum_text'), [new Date().getFullYear()])
      } catch (e) {
        console.log(e)
      }
    },
    changeLocale(locale: string) {
      this.$vuetify.locale.current = locale
    }
  }
})
</script>

<style lang="scss">
@import '@/styles/modules/_fonts.scss';
@import '@/styles/variables.scss';
@import '@mdi/font/css/materialdesignicons.css';
@import 'vuetify/_styles.scss';
@import '@/styles/customStyle.scss';
// do not add styles here!
// pls use customStyles
// only use scoped styles
</style>
