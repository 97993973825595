import { app, emitter } from '@/main'

import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { AxiosResponse, AxiosStatic } from 'axios'

export function configAxios(axios: AxiosStatic) {
  console.log('config axios interceptor')
  axios.defaults.headers.common['Accept'] = 'application/json'

  axios.interceptors.request.use(
    (config) => {
      console.log('intercepted!', config)
      const legalEntityStore = useLegalEntityStore()
      return {
        ...config,
        params: {
          ...config.params,
          ...{
            originId: legalEntityStore.getOriginId ? legalEntityStore.getOriginId : null,
            domainId: legalEntityStore.getSelectedPersonId ? legalEntityStore.getSelectedPersonId : null,
            userId: legalEntityStore.getSelectedApplicationUserId ? legalEntityStore.getSelectedApplicationUserId : null
          }
        },
        paramsSerializer: {
          indexes: null // by default: false. This is the new Version for the old paramserializer: return qs.stringify(params, { arrayFormat: 'repeat', filter: filterFunc })
        }
      }
    },
    (e) => Promise.reject(e)
  )

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      let errorMessage = ''
      if (error.response?.status === 401 || error.response?.status === 403) {
        errorMessage = error?.response?.data.detail
        //const translatedError = this.$t(error?.response?.data.title, error?.response?.data.placeholders)
        const formatter = new CustomFormatter()
        const formattedErrorMessage = formatter.interpolate(errorMessage, error?.response?.data.placeholders) ?? errorMessage

        emitter.emit('toastError', {
          message: formattedErrorMessage
        })

        if (error.response?.status === 401) {
          emitter.emit('backendUnAuthorized', {
            message: '401'
          })
        }
      }
      return Promise.reject(error)
    }
  )
}

/* if the response contain a header: "content-diposition filename=foobar.txt" it will overwrite your fileName */
export function fileCreator(response: AxiosResponse, fileName: string | undefined) {
  let fname = 'file.txt'
  if (fileName && fileName.length) {
    fname = fileName
  }
  if (response.headers['content-disposition'] && response.headers['content-disposition'].match(/filename=/i)) {
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('filename') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) fname = matches[1].replace(/['"]/g, '')
    }
  }
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fname) //or any other extension
  document.body.appendChild(link)
  link.click()
  return true
}

export function isXHRError(error: any) {
  return typeof error.response?.status === 'number'
}
export function isAJAXError(error: any) {
  return isXHRError(error)
}
export function isAxiosError(error: any) {
  return isXHRError(error)
}

export function showError(error: any, type: string = 'toasted') {
  console.error(error)
  if (!isXHRError(error)) {
    if (type == 'inline') {
      return JSON.stringify(error)
    } else {
      emitter.emit('toastError', {
        message: app.config.globalProperties.$t('toast_generic_error_message') + ' :: ' + JSON.stringify(error)
      })
    }
  }

  if (error.response?.status === 401 || error.response?.status === 403) {
    // already caught by axios interceptor above
    return true
  }

  if (error?.response?.data?.detail) {
    //its a custom error
    let errorMessage = error?.response?.data.detail
    const translatedError = app.config.globalProperties.$t(error?.response?.data.title, error?.response?.data.placeholders)
    const formatter = new CustomFormatter()
    let formattedErrorMessage = formatter.interpolate(errorMessage, error?.response?.data.placeholders) ?? errorMessage
    if (type == 'inline') {
      return translatedError !== error?.response?.data.title
        ? formatter.interpolate(translatedError, error?.response?.data.placeholders)
        : formattedErrorMessage
    } else {
      // toasted
      if (error.response.data.title.match(/DBM_ERROR/)) {
        // if the DBM_ERROR is translated
        if (app.config.globalProperties.$t(error.response.data.title) != error.response.data.title) {
          errorMessage = app.config.globalProperties.$t(error.response.data.title)
          formattedErrorMessage = formatter.interpolate(translatedError, error?.response?.data.placeholders) ?? errorMessage
        }
        emitter.emit('toastError', {
          message: formattedErrorMessage
        })
      } else {
        emitter.emit('toastError', {
          message: app.config.globalProperties.$t('toast_generic_error_message') + ' :: ' + JSON.stringify(error)
        })
      }
    }
  } else {
    // a generic java error
    if (type == 'inline') {
      return JSON.stringify(error)
    } else {
      let message = app.config.globalProperties.$t('toast_generic_error_message')
      if (Math.floor(error.response?.status / 100) !== 5 || app.config.globalProperties.$privileges.has({ path: '/debug', permission: 'show' })) {
        message += ' :: ' + JSON.stringify(error)
      }
      emitter.emit('toastError', {
        message: message
      })
    }
  }
}
