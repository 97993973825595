import { app, apiURL, emitter } from '@/main'
import { showError } from './axios'
import { usePrivilegesStore } from '@/store/privileges'

export class Privilege {
  path = '' // path without permission, without language, eg : /processingform, or /marketedMilks/findfarmermilk
  permission = '' // read | write | anything
}

class Privileges {
  public loading = false
  private static _instance: Privileges

  public static get Instance() {
    const instance = this._instance || (this._instance = new this())
    return instance
  }

  static privilegesDefault: Privilege[] = [
    { path: '/home', permission: 'read' }
    // { path: '/profile', permission: 'read' }
  ]

  async loadPrivileges() {
    try {
      privileges.loading = true
      usePrivilegesStore().fetchPrivileges()
    } catch (e) {
      showError(e)
    } finally {
      privileges.loading = false
    }
  }

  async loadLegacyApps() {
    try {
      const response = await app.axios.get(apiURL + '/privileges/legacyAccess')
      if (await response.data) {
        const data = await response.data
        const legacyApps = Object.keys(data).filter(function (app) {
          return data[app] === true
        })
        const privilegesStore = usePrivilegesStore()
        privilegesStore.setLegacyApps(legacyApps)
      }
    } catch (e) {
      showError(e)
    }
    return []
  }

  async load() {
    privileges.reset()
    await privileges.loadPrivileges()
    await privileges.loadLegacyApps()
  }

  has(requestedPrivilege: Privilege): boolean {
    const privilegesStore = usePrivilegesStore()
    const allPrivileges: Privilege[] =
      typeof app != 'undefined' ? [...Privileges.privilegesDefault, ...privilegesStore.privileges] : Privileges.privilegesDefault
    return (
      allPrivileges.findIndex(
        (element) =>
          element.path.toLowerCase() == requestedPrivilege.path.toLowerCase() && element.permission.toLowerCase() == requestedPrivilege.permission.toLowerCase()
      ) != -1
    )
  }

  hasOneOf(requestedPrivilege: Privilege[]): boolean {
    let test = false
    requestedPrivilege.forEach((el) => {
      test = test || privileges.has(el)
    })
    return test
  }
  hasAllOf(requestedPrivilege: Privilege[]): boolean {
    let test = true
    requestedPrivilege.forEach((el) => {
      test = test && privileges.has(el)
    })
    return test
  }

  reset() {
    const privilegesStore = usePrivilegesStore()
    privilegesStore.setPrivileges([])
    privilegesStore.setLegacyApps([])
  }
}

export const privileges = Privileges.Instance
